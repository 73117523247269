import { func, string, bool, object } from 'prop-types';
import cn from 'classnames/bind';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

import SVGIcons from 'assets';
import useToggle from 'hooks/useToggle';
import getComponents from 'utils/getComponents';

import styles from 'styles/components/input.module.scss';

const style = cn.bind(styles);

const defaultComponents = {
  Input: 'input',
};

const Input = ({
  label,
  name,
  value,
  onChange,
  error,
  placeholder,
  className,
  type,
  required,
  disabled,
  overrides,
  bottomText,
  innerRef,
  ...props
}) => {
  const { isOn, toggle } = useToggle();

  const {
    Input: { component: Input, props: InputProps },
  } = getComponents(defaultComponents, overrides);

  const translations = useTranslations('Input');

  const inputClassName = style({
    input: true,
    errorBorder: !!error,
    inputDisabled: disabled,
  });

  const labelClassName = style({
    errorLabel: !!error,
    filledLabel: !!value && !error && !disabled,
  });

  return (
    <div className={`${styles.inputWrapper} ${className}`}>
      {label && (
        <label className={`${labelClassName} p2-regular-14`} htmlFor={name}>
          {label}
          {required ? <span className={styles.required}>*</span> : ''}
        </label>
      )}
      {type === 'password' ? (
        <div className={styles.iconWrapper}>
          <Input
            className={`${inputClassName} p2-regular-14 `}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type={isOn ? 'text' : type}
            disabled={disabled}
            required={required}
            ref={innerRef}
            {...props}
            {...InputProps}
          />
          <div className={styles.imageWrapper}>
            <Image
              src={isOn ? SVGIcons.Eye : SVGIcons.EyeOff}
              alt={translations('alt.eyeToggle')}
              onClick={toggle}
              unoptimized
            />
          </div>
        </div>
      ) : (
        <Input
          className={`${inputClassName} p2-regular-14 `}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          required={required}
          ref={innerRef}
          {...props}
          {...InputProps}
        />
      )}

      {error && (
        <span className={`${styles.errorLabel} tag-regular-12`}>{error}</span>
      )}

      {!error && bottomText && (
        <span className="tag-regular-12">{bottomText}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  name: string.isRequired,
  label: string,
  value: string,
  onChange: func,
  error: string,
  type: string,
  required: bool,
  bottomText: string,
  innerRef: object,
};

export default Input;
