import { string } from 'prop-types';
import { useTranslations } from 'next-intl';
import Head from 'next/head';

const CustomHead = ({ pageName, title = '', description = '' }) => {
  const translations = useTranslations('CustomHead');
  return (
    <Head>
      <title>
        {translations('titles.prefix')}
        {pageName ? translations(`titles.${pageName}`) : title}
      </title>
      <meta
        name="description"
        content={
          pageName ? translations(`descriptions.${pageName}`) : description
        }
      />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
};

CustomHead.propTypes = {
  pageName: string,
  title: string,
  description: string,
};

export default CustomHead;
