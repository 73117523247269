import { useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { getSession, signIn } from 'next-auth/client';

import SVGIcons from 'assets';
import Form from 'components/common/Form';
import Modal from 'components/common/Modal';
import { ExistingSubscribersDisclaimer } from 'components/existing-subscribers-disclaimer';
import { ROUTES } from 'constants/routePaths';
import PATH_LABELS from 'constants/routePaths/routePathLabels.js';
import { REDIRECT_KEYS } from 'constants/';
import { useCheckRedirections } from 'hooks';
import getLanguageFromLocale from 'utils/getLanguageFromLocale';
import redirectToHome from 'utils/redirectToHome';
import CustomHead from 'components/common/CustomHead';
import httpClient from 'httpClient';
import SERVICES from 'constants/services';
import { BRAND_TYPES } from 'constants/index';

import styles from 'styles/pages/login.module.scss';
import { useTracking } from 'react-tracking';

const IMAGE_DIMENSIONS = {
  height: 36,
  width: 224,
};

export default function Login() {
  const router = useRouter();
  const { trackEvent } = useTracking({ page: 'Login' });
  const { checkRedirections, transferQueryParams } = useCheckRedirections();

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const translations = useTranslations('Login');
  const title = translations('title');
  const buttonLabel = translations('labels.loginBtn');
  const preLinkText = translations('preLink');

  const handleOnChange = ({ target: { name, value } }) => {
    setFormValues((currentValues) => ({ ...currentValues, [name]: value }));
  };

  const forgotPasssword = {
    text: translations('forgotPass'),
    href: ROUTES.forgotPassword,
  };

  const link = {
    text: translations('signUpLink'),
    href:
      router.query.store_id ||
      router.query[REDIRECT_KEYS.index] ||
      router.query[REDIRECT_KEYS.purchase]
        ? transferQueryParams(router.asPath, ROUTES.emailLookup)
        : ROUTES.emailLookup,
  };

  const emailInput = {
    props: {
      name: 'email',
      label: translations('labels.email'),
      type: 'email',
      onChange: handleOnChange,
      required: true,
    },
  };

  const passwordInput = {
    props: {
      name: 'password',
      label: translations('labels.password'),
      type: 'password',
      required: true,
      onChange: handleOnChange,
    },
  };

  const checkEnterpriseProvider = async () => {
    const session = await getSession();
    try {
      const { error, data } = await httpClient.request({
        headers: {
          'access-token': session.user.accessToken,
          client: session.user.client,
          uid: session.user.uid,
        },
        url: `${SERVICES.enterpriseProvider}/${router.query.referrer_id}`,
        method: 'GET',
      });
      if (error) {
        setError(translations('enterpriseProvider'));
      } else {
        const { enterpriseProvider } = data;
        let route = ROUTES.subscriptionPlanSelector;

        if (enterpriseProvider) {
          const { isClaimable, isActive, isFree, subscriptionPlanId } =
            enterpriseProvider;

          if (isClaimable && isActive && isFree) {
            // Claim free enterprise sub
            route = ROUTES.subscriptionEntitlement;
          } else if (isClaimable && isActive && !isFree) {
            // Claim discounted enterprise sub
            route = ROUTES.subscriptionPayment;
            router.query.sp = subscriptionPlanId;
            router.query.type = BRAND_TYPES.singleBrands;
          } else if (!isClaimable && isActive) {
            // Enterprise max limit reached
            router.query.maxLimitReached = 'true';
          } else if (!isActive) {
            // Enterprise offer expired
            router.query.invalidProvider = 'true';
          }
        }

        router.push({
          pathname: route,
          query: router.query,
        });
      }
    } catch (err) {
      setIsLoading(false);
      setError(translations('enterpriseProvider'));
    }
  };

  const loginInputs = [emailInput, passwordInput];

  const formProps = {
    inputs: loginInputs,
    title,
    forgotPasssword,
    buttonLabel,
    preLinkText,
    footerLink: link,
    isLoading,
    error,
    onSubmit: async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const { error } = await signIn('credentials', {
          email: formValues.email,
          password: formValues.password,
          redirect: false,
        });
        setIsLoading(false);
        if (error) {
          setError(translations('form.credentialError'));
        } else if (!checkRedirections()) {
          trackEvent({ event: 'Sign In', signInType: 'Email' });
          if (router.query.referrer_id) checkEnterpriseProvider();
          else router.push(ROUTES.home);
        }
      } catch (err) {
        setIsLoading(false);
        setError(translations('form.error'));
      }
    },
  };

  const onDisclaimerClick = () => {
    setShowDisclaimer(true);
  };

  const onDisclaimerToggle = () => {
    setShowDisclaimer((prev) => !prev);
  };

  const showDisclaimerBanner = JSON.parse(
    process.env.NEXT_PUBLIC_LOGIN_SHOW_ACTIVATE_BANNER || false
  );

  return (
    <>
      <CustomHead pageName={PATH_LABELS.get(ROUTES.login)} />
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.mainContainer}>
            <div className={styles.mainHeader}>
              <div className={styles.logo}>
                <Image
                  src={SVGIcons.brands.GoLogoBlack}
                  alt={translations('altGO')}
                  height={IMAGE_DIMENSIONS.height}
                  width={IMAGE_DIMENSIONS.width}
                  unoptimized
                />
              </div>
            </div>
            {showDisclaimerBanner && (
              <div className={styles.disclaimerContainer}>
                {translations('existingSubscriber')}
                <button
                  className={styles.buttonLink}
                  onClick={onDisclaimerClick}
                >
                  {translations('activateSubscription')}
                </button>
              </div>
            )}
            <Form {...formProps} />
          </div>
        </div>
        <div className={styles.image}>
          <div className={styles.logoMobile}>
            <Image
              src={SVGIcons.brands.GoLogo}
              alt={translations('altGO')}
              height={IMAGE_DIMENSIONS.height}
              width={IMAGE_DIMENSIONS.width}
              unoptimized
            />
          </div>
        </div>
        <Modal
          isOn={showDisclaimer}
          toggle={onDisclaimerToggle}
          containerClassName={styles.modalContainer}
        >
          <ExistingSubscribersDisclaimer />
        </Modal>
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  const session = await getSession(context);
  const language = getLanguageFromLocale(context.locale);

  if (session) return redirectToHome();

  return {
    props: {
      messages: require(`messages/pages/login/${language}`).messages,
    },
  };
}
