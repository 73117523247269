import { bool, func } from 'prop-types';

import styles from 'styles/components/terms-and-conditions.module.scss';

const TermsAndCondition = ({
  combinePolicies = false,
  isPrivacyChecked,
  setIsPrivacyChecked,
  isTermsChecked,
  setIsTermsChecked,
  preAgreements,
  agreementsLink,
  privacyLink,
}) => {
  const terms = {
    ...agreementsLink,
    isChecked: isTermsChecked,
    setIsChecked: setIsTermsChecked,
  };
  const privacy = {
    ...privacyLink,
    isChecked: isPrivacyChecked,
    setIsChecked: setIsPrivacyChecked,
  };

  if (combinePolicies) {
    return (
      <div>
        <div className={`p-medium-14 ${styles.agreements}`}>
          <input
            type="checkbox"
            className={styles.checkbox}
            value={isTermsChecked}
            onChange={() => {
              setIsTermsChecked(!!isTermsChecked);
              setIsPrivacyChecked(!!isTermsChecked);
            }}
          />
          <div className={`h4-bold-14-m ${styles.termLink}`}>
            <span className={`p-medium-14 ${styles.termText}`}>
              {preAgreements}
            </span>
            <a href={terms?.href} rel="noreferrer" target="_blank">
              {terms?.text.replace('.', '')}
            </a>
            &nbsp;and to the&nbsp;
            <a href={privacy?.href} rel="noreferrer" target="_blank">
              {privacy?.text}
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {[terms, privacy].map((link, index) => (
          <div key={index} className={`p-medium-14 ${styles.agreements}`}>
            <input
              type="checkbox"
              className={styles.checkbox}
              value={link.isChecked}
              onChange={link.setIsChecked}
            />
            <div className={`h4-bold-14-m ${styles.termLink}`}>
              <span className={`p-medium-14 ${styles.termText}`}>
                {preAgreements}
              </span>
              <a href={link?.href} rel="noreferrer" target="_blank">
                {link?.text}
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
};

TermsAndCondition.propTypes = {
  combinePolicies: bool,
  isTermsChecked: bool.isRequired,
  setIsTermsChecked: func.isRequired,
  isPrivacyChecked: bool.isRequired,
  setIsPrivacyChecked: func.isRequired,
};

export default TermsAndCondition;
