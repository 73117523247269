import Link from 'next/link';
import { arrayOf, func, string, shape, bool, number } from 'prop-types';
import cn from 'classnames/bind';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import TermsAndConditions from 'components/common/TermsAndConditions';
import styles from 'styles/components/form.module.scss';

const style = cn.bind(styles);

const INPUTS_WRAP_THRESHOLD = 3;

const Form = ({
  inputs,
  buttonLabel,
  title,
  description,
  forgotPasssword,
  preLinkText,
  footerLink,
  onSubmit,
  error,
  preAgreements,
  agreementsLink,
  privacyLink,
  onClick,
  lastInput,
  lastInputAddedClassname,
  isTermsChecked = true,
  setIsTermsChecked,
  isPrivacyChecked = true,
  setIsPrivacyChecked,
  isLoading,
  withMaxWidth = true,
  buttonBrandId,
  className = '',
}) => {
  const thresholdOvercame = inputs.length > INPUTS_WRAP_THRESHOLD;

  const inputsContainerClassName = style({
    inputsContainer: true,
    inputsContainerWrap: thresholdOvercame,
  });

  const inputClassName = style({
    input: true,
    inputWrap: thresholdOvercame && withMaxWidth,
  });

  const lastInputClassName = style({
    input: true,
    inputWrap: thresholdOvercame && withMaxWidth,
    lastInput: true,
    [lastInputAddedClassname]: Boolean(lastInputAddedClassname),
  });

  const buttonClassName = style({
    button: true,
    buttonWrap: thresholdOvercame && withMaxWidth,
  });

  const ContainerComp = onClick ? 'div' : 'form';

  return (
    <>
      <ContainerComp
        className={`${styles.form} ${className}`}
        onSubmit={onSubmit}
      >
        {title && <span className={`${styles.title} h2-bold-24`}>{title}</span>}
        {description}
        {error && <p className={`p2-regular-14 red`}>{error}</p>}
        <div className={inputsContainerClassName}>
          {inputs.map(({ props }, index) => (
            <Input
              key={index}
              name={props.name}
              label={props.label}
              onChange={props.onChange}
              className={
                props.name === lastInput ? lastInputClassName : inputClassName
              }
              type={props.type}
              {...props}
            />
          ))}
        </div>

        {forgotPasssword && (
          <div className={`p-medium-14 ${styles.forgotPsw}`}>
            <Link href={forgotPasssword.href}>{forgotPasssword.text}</Link>
          </div>
        )}

        {preAgreements && (
          <TermsAndConditions
            preAgreements={preAgreements}
            agreementsLink={agreementsLink}
            privacyLink={privacyLink}
            isTermsChecked={isTermsChecked}
            setIsTermsChecked={setIsTermsChecked}
            isPrivacyChecked={isPrivacyChecked}
            setIsPrivacyChecked={setIsPrivacyChecked}
          />
        )}

        <Button
          label={buttonLabel}
          overrides={{
            ButtonElem: { className: buttonClassName },
          }}
          onClick={onClick || null}
          isLoading={isLoading}
          disabled={isLoading || !isTermsChecked || !isPrivacyChecked}
          brandId={buttonBrandId}
        />
      </ContainerComp>

      {preLinkText && footerLink && (
        <div className={`${styles.footer} p-medium-14 `}>
          <span>{preLinkText}</span>

          <div className={`${styles.link} h4-bold-14-m`}>
            <Link href={footerLink.href}>{footerLink.text}</Link>
          </div>
        </div>
      )}
    </>
  );
};

Form.propTypes = {
  inputs: arrayOf(
    shape({
      props: shape({
        name: string.isRequired,
        label: string.isRequired,
        placeholder: string,
        value: string,
        type: string,
        onChange: func.isRequired,
      }),
    })
  ).isRequired,
  buttonLabel: string.isRequired,
  title: string,
  forgotPasssword: shape({
    text: string.isRequired,
    href: string.isRequired,
  }),
  preLinkText: string,
  footerLink: shape({
    text: string.isRequired,
    href: string.isRequired,
  }),
  onSubmit: func,
  error: string,
  isLoading: bool,
  preAgreements: string,
  agreementsLink: shape({
    text: string.isRequired,
    href: string.isRequired,
  }),
  privacyLink: shape({
    text: string.isRequired,
    href: string.isRequired,
  }),
  onSubmitClick: func,
  lastInput: string,
  setIsTermsChecked: func,
  setIsPrivacyChecked: func,
  withMaxWidth: bool,
  buttonBrandId: number,
  className: string,
  lastInputAddedClassname: string,
};

export default Form;
