import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { useSession } from 'next-auth/client';
import { string } from 'prop-types';

import { ROUTES } from 'constants/routePaths';

import styles from 'styles/components/existing-subscribers-disclaimer.module.scss';

export const ExistingSubscribersDisclaimer = ({
  webLinkLabel = '',
  webLink = '',
}) => {
  const translations = useTranslations('ExistingSubscribersDisclaimer');
  const [session] = useSession();
  const showAuthenticatedInfo = !!session?.user;

  return (
    <>
      <div className={styles.infoWrapper}>
        <h3 className={styles.title}>{translations('web.title')}</h3>
        <p className={styles.subtitle}>
          {showAuthenticatedInfo
            ? translations('web.subtitle')
            : translations('web.guestSubtitle')}
        </p>
        <div>
          {showAuthenticatedInfo ? (
            <div>
              <p>{translations('web.info')}</p>
              <Link href={webLink || ROUTES.linkedAccounts} passHref>
                <span className={`h4-bold-14 ${styles.link}`}>
                  {webLinkLabel || translations('web.linkAccount')}
                </span>
              </Link>
            </div>
          ) : (
            <div className={styles.steps}>
              <div className={styles.step}>
                <span className={styles.secondaryNumber}>1</span>
                <p className={styles.text}>{translations('web.firstStep')}</p>
              </div>
              <div className={styles.step}>
                <span className={styles.secondaryNumber}>2</span>
                <p className={styles.text}>{translations('web.secondStep')}</p>
              </div>
              <div className={styles.step}>
                <span className={styles.secondaryNumber}>3</span>
                <p className={styles.text}>{translations('web.thirdStep')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.infoWrapper}>
        <h3 className={styles.title}>{translations('mobile.title')}</h3>
        <p className={styles.subtitle}>{translations('mobile.subtitle')}</p>
        <div className={styles.steps}>
          <div className={styles.step}>
            <span className={styles.number}>1</span>
            <p className={styles.text}>{translations('mobile.firstStep')}</p>
          </div>
          <div className={styles.step}>
            <span className={styles.number}>2</span>
            <p className={styles.text}>{translations('mobile.secondStep')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

ExistingSubscribersDisclaimer.propTypes = {
  webLinkLabel: string,
  webLink: string,
};
